/* Common Imports */
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Typography,
  Checkbox,
  Divider,
  Snackbar,
  FormControlLabel,
  Link,
} from "@material-ui/core";
import MuiIconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DialogActions } from "@mui/material";
import MuiAlert from "@material-ui/lab/Alert";

/* Data Imports */
import AppConfig from "../../AppConfig";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  heading: {
    width: "100%",
    color: "#0FCA56",
    // background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
    // WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent",
    fontWeight: "700",
    fontSize: "1.75rem",
    lineHeight: "2.1rem",
    margin: "0rem 0rem 0rem 0rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.2rem",
    },
  },
  contactForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "space-between",
    width: "100%",
  },
  textField: {
    margin: "0rem 0rem 2rem 0rem",
    width: "100%",
  },
  checkboxRow: {
    padding: "0rem 0rem 1rem 0.5rem",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.up("lg")]: {
      alignItems: "center",
    },
  },
  grey: {
    color: "#666666",
  },
  dialogActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    padding: "1rem",
    margin: "0.5rem 0rem 0.5rem 0rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      justifyContent: "center",
    },
  },
  primaryButton: {
    margin: "0rem 1rem 0rem 0rem",
    background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
    color: "#FFFFFF",
    borderRadius: "0rem",
    borderWidth: "0px",
    fontWeight: "500",
    boxShadow: "none",
    padding: "0.5rem 1rem",
    "&:hover": {
      background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
      color: "#FFFFFF",
      textDecoration: "underline",
      textUnderlineOffset: "3px",
      boxShadow: "none",
    },
    "&:disabled": {
      background: "#ececec",
      color: "##88888a",
      border: "solid 1px #ececec",
    },
    [theme.breakpoints.down("xs")]: {
      width: "69%",
      margin: "0rem 0rem 1rem 0rem",
    },
  },
  cancelButton: {
    margin: "0rem 1rem 0rem 0rem",
    background: "#FFFFFF",
    color: "#0FCA56",
    borderRadius: "0rem",
    border: "solid 1px #0FCA56",
    borderWidth: "2px",
    fontWeight: "500",
    boxShadow: "none",
    "&:hover": {
      background: "#FFFFFF",
      textDecoration: "underline",
      boxShadow: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "69%",
      margin: "0rem 0rem 0rem 0rem",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const VisitModal = (props) => {
  const classes = useStyles();

  const [loadingSnackbar, setLoadingSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [atc, setAtc] = React.useState(false);
  const [btnDisabled, setBtnDisabled] = React.useState(true);

  React.useEffect(() => {
    if (
      name !== (undefined || "") &&
      phone !== (undefined || "") &&
      atc === true
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [name, phone, atc]);

  const submitForm = () => {
    const reqURL = encodeURI(
      AppConfig.apiUrlPrefix +
        "contact?name=" +
        name +
        "&email=" +
        email +
        "&phone=" +
        phone +
        "&message=" +
        message +
        "&atc=" +
        atc +
        "&source=" +
        (props.source ? props.source : "unknown")
    );
    setLoadingSnackbar(true);
    fetch(reqURL)
      .then((res) => {
        res
          .json()
          .then((data) => {
            console.log(data);
            if (data.status === "success") {
              setLoadingSnackbar(false);
              setSuccessSnackbar(true);
            } else {
              setLoadingSnackbar(false);
              setErrorSnackbar(true);
            }
            if (typeof props.submitCallback === "function") {
              props.submitCallback(data);
            }
            if (typeof submitCallback === "function") submitCallback(data);
            /* TODO: Send Slack message with lead */
          })
          .catch((error) => {
            console.log(error);
            setLoadingSnackbar(false);
            setErrorSnackbar(true);
            if (typeof submitCallback === "function") submitCallback(error);
            /* TODO: Send Slack message with error */
          });
      })
      .catch((error) => {
        console.log(error);
        setLoadingSnackbar(false);
        setErrorSnackbar(true);
        if (typeof submitCallback === "function") submitCallback(error);
        /* TODO: Send Slack message with error */
      });
  };

  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbar(false);
  };
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackbar(false);
  };

  return (
    <React.Fragment>
      <Snackbar
        open={loadingSnackbar}
        autoHideDuration={3000}
        onClose={handleLoadingSnackbarClose}
      >
        <Alert onClose={handleLoadingSnackbarClose} severity="info">
          Sending information...
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={3000}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Thanks, {name}! We&apos;ll get in touch shortly.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="error">
          Oops! Something has gone wrong! Please try again.
        </Alert>
      </Snackbar>
      <Dialog
        className={classes.modal}
        onClose={props.onClose}
        aria-labelledby="kyc-verification-modal"
        open={props.open}
        maxWidth="sm"
      >
        <DialogTitle id="user-modal" onClose={props.onClose}>
          <Typography variant="h4" component="span" className={classes.heading}>
            Book Site Visit
          </Typography>
          <MuiIconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.onClose}
          >
            <CloseIcon />
          </MuiIconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <form id="cf" className={classes.contactForm} method="GET">
            <TextField
              id="name"
              name="name"
              label="Name"
              required={true}
              type="text"
              className={classes.textField}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <label htmlFor="name"></label>
            <TextField
              id="phone"
              name="phone"
              label="Phone"
              required={true}
              type="text"
              className={classes.textField}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
            />
            <label htmlFor="phone"></label>
            <TextField
              id="email"
              name="email"
              label="Email"
              type="email"
              className={classes.textField}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <label htmlFor="email"></label>
            <TextField
              id="message"
              name="message"
              label="Message"
              type="text"
              multiline={true}
              rows={2}
              className={classes.textField}
              onChange={(event) => {
                setMessage(event.target.value);
              }}
            />
            <label htmlFor="message"></label>
            <input
              id="source"
              type="hidden"
              name="source"
              value={props.source}
              onChange={(event) => {
                setSource(event.target.value);
              }}
            />
            <div className={classes.checkboxRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ padding: "0rem 0.5rem 0rem 0rem" }}
                    checked={atc}
                    onChange={(event) => {
                      setAtc(!atc);
                    }}
                    color="primary"
                    value={atc}
                    name="agree"
                  />
                }
                label={
                  <span>
                    <span className={classes.grey}>
                      I have read and agree with the{" "}
                    </span>
                    <Link
                      href="/terms"
                      target="_blank"
                      aria-label="Terms of Service"
                      title="Terms of Service"
                      rel="noopener"
                    >
                      Terms of Service{" "}
                    </Link>
                    <span className={classes.grey}>and</span>{" "}
                    <Link
                      href="/privacy"
                      target="_blank"
                      aria-label="Privacy Policy"
                      title="Privacy Policy"
                      rel="noopener"
                    >
                      Privacy Policy
                    </Link>
                  </span>
                }
              />
            </div>
          </form>
        </DialogContent>
        <Divider />

        <DialogActions className={classes.dialogActions} disableSpacing>
          <Button
            variant="contained"
            title="Cancel"
            onClick={props.onClose}
            rel="noopener"
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            title="Submit"
            onClick={submitForm}
            disabled={btnDisabled}
            rel="noopener"
            className={classes.primaryButton}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default VisitModal;
