/* Common Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */
import {
  Paper,
  TextField,
  Button,
  Snackbar,
  FormControlLabel,
  Checkbox,
  Link,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

/* Data Imports */
import AppConfig from "../../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  contactPaper: {
    padding: "2rem 1.5rem 2rem 1.5rem",
    width: "42%",
    [theme.breakpoints.only("md")]: {
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  contactForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "space-between",
    width: "100%",
  },
  textField: {
    margin: "0rem 0rem 2rem 0rem",
    width: "100%",
  },
  checkboxRow: {
    padding: "0rem 0rem 2rem 0.5rem",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.up("lg")]: {
      alignItems: "center",
    },
  },
  grey: {
    color: "#666666",
  },
  btnRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    alignItems: "center",
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    margin: "0em 0.5rem 0rem 0rem",
    gap: "2rem",
    [theme.breakpoints.down("xs")]: {
      margin: "2rem 0rem 0rem 0rem",
    },
  },
  primaryButton: {
    background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
    color: "#FFFFFF",
    borderRadius: "0rem",
    borderWidth: "0px",
    fontSize: "1.3rem",
    fontWeight: "500",
    textTransform: "none",
    width: "100%",
    boxShadow: "none",
    "&:hover": {
      background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
      textDecoration: "underline",
      textUnderlineOffset: "3px",
      boxShadow: "none",
    },
    "&:disabled": {
      background: "#ececec",
      color: "##88888a",
      border: "solid 1px #ececec",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      width: "100%",
    },
  },
  btnIcon: {
    marginLeft: "1rem",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ContactForm = (props) => {
  const classes = useStyles();

  const [loadingSnackbar, setLoadingSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [atc, setAtc] = React.useState(false);
  const [btnDisabled, setBtnDisabled] = React.useState(true);

  React.useEffect(() => {
    if (
      name !== (undefined || "") &&
      phone !== (undefined || "") &&
      atc === true
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [name, phone, atc]);

  const submitForm = () => {
    const reqURL = encodeURI(
      AppConfig.apiUrlPrefix +
        "contact?name=" +
        name +
        "&email=" +
        email +
        "&phone=" +
        phone +
        "&message=" +
        message +
        "&atc=" +
        atc +
        "&source=" +
        (props.source ? props.source : "unknown")
    );
    setLoadingSnackbar(true);
    fetch(reqURL)
      .then((res) => {
        res
          .json()
          .then((data) => {
            console.log(data);
            if (data.status === "success") {
              setLoadingSnackbar(false);
              setSuccessSnackbar(true);
            } else {
              setLoadingSnackbar(false);
              setErrorSnackbar(true);
            }
            if (typeof submitCallback === "function") submitCallback(data);
            /* TODO: Send Slack message with lead */
          })
          .catch((error) => {
            console.log(error);
            setLoadingSnackbar(false);
            setErrorSnackbar(true);
            if (typeof submitCallback === "function") submitCallback(error);
            /* TODO: Send Slack message with error */
          });
      })
      .catch((error) => {
        console.log(error);
        setLoadingSnackbar(false);
        setErrorSnackbar(true);
        if (typeof submitCallback === "function") submitCallback(error);
        /* TODO: Send Slack message with error */
      });
  };

  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbar(false);
  };
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackbar(false);
  };

  return (
    <React.Fragment>
      <Snackbar
        open={loadingSnackbar}
        autoHideDuration={3000}
        onClose={handleLoadingSnackbarClose}
      >
        <Alert onClose={handleLoadingSnackbarClose} severity="info">
          Sending information...
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={3000}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Thanks, {name}! We&apos;ll get in touch shortly.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="error">
          Oops! Something has gone wrong! Please try again.
        </Alert>
      </Snackbar>
      <Paper elevation={3} className={classes.contactPaper}>
        <form id="cf" className={classes.contactForm} method="GET">
          <TextField
            id="name"
            name="name"
            label="Name"
            required={true}
            type="text"
            className={classes.textField}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <label htmlFor="name"></label>
          <TextField
            id="phone"
            name="phone"
            label="Phone"
            required={true}
            type="text"
            className={classes.textField}
            onChange={(event) => {
              setPhone(event.target.value);
            }}
          />
          <label htmlFor="phone"></label>
          <TextField
            id="email"
            name="email"
            label="Email"
            type="email"
            className={classes.textField}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <label htmlFor="email"></label>
          <TextField
            id="message"
            name="message"
            label="Message"
            type="text"
            multiline={true}
            rows={2}
            className={classes.textField}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
          />
          <label htmlFor="message"></label>
          <input
            id="source"
            type="hidden"
            name="source"
            value={props.source}
            onChange={(event) => {
              setSource(event.target.value);
            }}
          />
          <div className={classes.checkboxRow}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ padding: "0rem 0.5rem 0rem 0rem" }}
                  checked={atc}
                  onChange={(event) => {
                    setAtc(atc === true ? false : true);
                  }}
                  color="primary"
                  value={atc}
                  name="agree"
                />
              }
              label={
                <span>
                  <span className={classes.grey}>
                    I have read and agree with the{" "}
                  </span>
                  <Link
                    href="/terms"
                    target="_blank"
                    aria-label="Terms of Service"
                    title="Terms of Service"
                    rel="noopener"
                  >
                    Terms of Service {""}
                  </Link>
                  <span className={classes.grey}>and</span>{" "}
                  <Link
                    href="/privacy"
                    target="_blank"
                    aria-label="Privacy Policy"
                    title="Privacy Policy"
                    rel="noopener"
                  >
                    Privacy Policy
                  </Link>
                </span>
              }
            />
          </div>
          <div className={classes.btnRow}>
            <Button
              variant="outlined"
              title="Submit"
              onClick={submitForm}
              disabled={btnDisabled}
              rel="noopener"
              className={classes.primaryButton}
              // onMouseEnter={handleContactBtnMouseEnter}
              // onMouseLeave={handleContactBtnMouseLeave}
            >
              Get a call
              <img
                className={classes.btnIcon}
                src="/images/icons/misc/Vector1.svg"
                alt={AppConfig.imageAltText}
                width={16}
                height={16}
                loading="lazy"
                referrerPolicy="no-referrer"
              />
            </Button>
          </div>
        </form>
      </Paper>
    </React.Fragment>
  );
};

export default ContactForm;
